<template>
  <div class="container">
    <div class="header">
      <div class="title">{{article.title}}</div>
      <div class="time">发布时间：{{article.createDate}}</div>
    </div>
    <div class="h-content">
      <div class="ql-content ql-editor" v-html="article.content"></div>
    </div>
<!--    <div class="content">
      <div class="ql-editor" v-html="article.content"></div>
    </div>-->
  </div>
</template>

<script>
import request from "@/api/index.js";
export default {
  name: '',
  components: {},
  props: {
    aid: String,
  },
  data() {
    return {
      article: {},
      content: ''
    }
  },
  computed: {

  },
  watch: {
    'article.content': {
      handler: function(val) {
        if(!val) return
        this.formateDocument()
      },
      immediate: true,
    }

  },
  created() {

  },
  mounted() {
    let aId= this.$route.query.aId
    request.get(process.env.VUE_APP_PX_URL +`/swhome/api/article/article/${aId}`).then(res => {
      let data = res.data || {}

      data.content = data.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, capture) {
        if (match) {
          return match.replace( /src=[\'\"]?([^\'\"]*)[\'\"]?/gi, function(src, n) {
            let origin = process.env.VUE_APP_ORIGIN || ''
            //去除相对路径../..  同时排除绝对路径
            return  (n.indexOf("http") == 0)? src : ("src='" + origin + n.replace(/[.]{2}\/[.]{2}/, "") +"'");
          })
        }
      })
      this.article = data
      document.title= this.article.title
    })
  },
  methods: {
    formateDocument: function() {
      this.$nextTick(() => {
        document.querySelectorAll('.content p').forEach(p => {
          let img = p.querySelectorAll('img')
          if(p.style.textIndent) p.style.textIndent = '2em'
          if(img.length) {
            p.style.textIndent = 0
            img.forEach(item => {
              item.width = undefined
              item.height = 'auto'
              item.style.width = 'auto'
              item.style.height = 'auto'
              item.style.maxWidth = '100%'
              item.onclick = function() {
                window.open(item.src)
              }
            })
          }
        });

        document.querySelectorAll('.content table').forEach(table => {
          table.removeAttribute('width')
        })
      })
    },
  },
}
</script>
<style lang='scss' scoped>
@import '../style/ql.css';
.container {
  padding: 20px 0;
  background-color: #FFFFFF;
}
@media screen and (min-width: 450px) {
  .container {
    margin: 0 auto;
    width: 420px;
  }
}
.content ::v-deep {
  // .selectTdClass{background-color:#edf5fa !important}
  table.noBorderTable td,table.noBorderTable th,table.noBorderTable caption{border:1px dashed #ddd !important}
  table{max-width: 100%!important; margin-bottom:10px; border-collapse:collapse; display:table; border: 1px solid #000;}
  td,th{padding: 5px 10px;border: 1px solid #DDD;}
  caption{border:1px dashed #DDD;border-bottom:0;padding:3px;text-align:center;}
  th{border-top:1px solid #BBB;background-color:#F7F7F7;}
  table tr.firstRow th{border-top-width:2px;}
  .ue-table-interlace-color-single{ background-color: #fcfcfc; }
  .ue-table-interlace-color-double{ background-color: #f7faff; }
  td p{margin:0;padding:0;}

  p { word-break: break-all; }
}
// .content ::v-deep p {
//   text-indent: 2em;
//   line-height: 2.5em;
// }


.header {
  border-bottom: 1px solid #DCDEE0;
  padding-bottom: 20px;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 34px;
    margin-bottom: 12px;
  }
  .time {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969799;
    line-height: 14px;
  }
}
.h-content {
  padding: 20px 12px;
}
.ql-content {
  padding: 20px 12px;
  border-radius: 8px;
  border: 2px solid #FFB759;
  background: #FFFAF4;
}
.content {
  background-image: url("~@/assets/img/articleDetailBg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  padding-top: 50px;
}
</style>
